import { Nullish } from "@/common/types/Nullish";
import { Maybe } from "@/common/types/Maybe";
import { makeBinded } from "@/common/helpers/objects";

class VersionListener extends EventTarget {
  private _interval: Maybe<number> = undefined;
  private _currentVersion: Nullish<string> = null;
  private _ms = 30_000;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onChange = () => {};

  public async init(onChange: (...args: any[]) => any, ms = this._ms) {
    this._ms = ms;
    this._onChange = onChange;

    this._currentVersion ??= await this._getVersion();

    this._interval = setInterval(async () => {
      const newVersion = await this._getVersion();

      if (newVersion !== this._currentVersion) {
        this._currentVersion = newVersion ?? null;
        this._onChange();
      }
    }, this._ms);
  }

  private async _getVersion() {
    const payload = await fetch("/version.json");
    if (payload.ok)
      return ((await payload.json()) as { version: string }).version;
    else return null;
  }

  public stop() {
    clearInterval(this._interval);
  }

  public reset() {
    this.init(this._onChange, this._ms);
  }
}

const versionListener = makeBinded(VersionListener);

document.addEventListener("visibilitychange", () => {
  if (document.visibilityState === "hidden") {
    versionListener.stop();
  } else {
    versionListener.reset();
  }
});

export { versionListener, VersionListener };
