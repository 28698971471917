import { httpService } from "@/common/services/http";
import { baseQuery, type QueryRequestDto } from "@/common/types/QueryDIA";
import {
  type Communication,
  COMMUNICATION_ABSTRACT_STATUS,
  COMMUNICATION_FILTER,
  COMMUNICATION_STATUS,
  COMMUNICATION_TYPE,
} from "@/common/entities/Communication";
import { defineStore } from "pinia";
import useCorrespondencesStore, {
  type CorrespondencesStore,
} from "@/resources/correspondences/stores";
import useCondoCorrespondencesStore, {
  type CondoCorrespondencesStore,
} from "@/resources/condoCorrespondences/stores";
import useDeliveriesStore, {
  type DeliveriesStore,
} from "@/resources/deliveries/stores";
import useTrackedDeliveriesStore, {
  type TrackedDeliveriesStore,
} from "@/resources/tracked-deliveries/stores";
import { type Nullish } from "@/common/types/Nullish";
import { CONCERN_STATUS } from "@/common/entities/Submission";

type CommunicationStatusMeta = {
  status_color: string;
  status_label: string;
};
type CommunicationMeta = CommunicationStatusMeta & {
  icon: string;
  title: string;
};

const store = defineStore("communications", {
  state: () => ({
    endpoint: `${process.env.VUE_APP_AMMINISTRO_API_GATEWAY}/api/v2/communications`,
    title: "Tutti gli immobili",
    icon: "fa:fas fa-building",
    viewRoute: "viewCommunications",

    // INTERNAL DATA
    isCreating: false,
  }),
  getters: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    queries(_state) {
      return {
        count: {
          ...baseQuery,
        },

        all: {
          ...baseQuery,
          offset: 0,
          limit: 36,
        },

        get sent() {
          return {
            ...this.all,
            where: {
              status: COMMUNICATION_STATUS.SENT,
            },
          };
        },

        get draft() {
          return {
            ...this.all,
            where: {
              status: COMMUNICATION_STATUS.DRAFT,
            },
          };
        },

        get problematic() {
          return {
            ...this.all,
            where: {
              lowerConcernStatus: CONCERN_STATUS.PROBLEMATIC,
              notProblematic: false,
            },
          };
        },

        get processing() {
          return {
            ...this.all,
            where: {
              lowerConcernStatus: CONCERN_STATUS.PROCESSING,
            },
          };
        },

        get saved() {
          return {
            ...this.all,
            where: {
              lowerConcernStatus: CONCERN_STATUS.SAVED,
            },
          };
        },

        get unread() {
          return {
            ...this.all,
            where: {
              lowerConcernStatus: { $eq: CONCERN_STATUS.READTRACKING_UNREAD },
            },
          };
        },

        get delivered() {
          return {
            ...this.all,
            where: {
              lowerConcernStatus: {
                $in: [CONCERN_STATUS.PROCESSING, CONCERN_STATUS.SAVED],
              },
              status: { $neq: COMMUNICATION_STATUS.DRAFT },
            },
          };
        },
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    factory(_state) {
      return (type: COMMUNICATION_TYPE) => {
        switch (type) {
          case COMMUNICATION_TYPE.AVVISO_CONDOMINIALE:
            return useCondoCorrespondencesStore();
          case COMMUNICATION_TYPE.COMUNICAZIONE:
            return useCorrespondencesStore();
          case COMMUNICATION_TYPE.POSTA:
            return useDeliveriesStore();
          case COMMUNICATION_TYPE.POSTA_TRACCIATA:
            return useTrackedDeliveriesStore();
        }
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getAbstractStatus(_state) {
      return (communication: Communication): COMMUNICATION_ABSTRACT_STATUS => {
        let abstract_status: Nullish<COMMUNICATION_ABSTRACT_STATUS> = null;

        switch (communication.status) {
          case COMMUNICATION_STATUS.DRAFT:
            abstract_status = COMMUNICATION_ABSTRACT_STATUS.DRAFT;
            break;
          case COMMUNICATION_STATUS.VALIDATION:
          case COMMUNICATION_STATUS.PREPARING:
            abstract_status = COMMUNICATION_ABSTRACT_STATUS.PREPARING;
            break;
          case COMMUNICATION_STATUS.SENDING:
          case COMMUNICATION_STATUS.SENT:
            abstract_status = COMMUNICATION_ABSTRACT_STATUS.SENT;
            break;
          case COMMUNICATION_STATUS.SENDING_FAILED:
            abstract_status = COMMUNICATION_ABSTRACT_STATUS.ERROR;
            break;
        }

        if (
          communication.lastIssues?.length > 0 &&
          abstract_status === COMMUNICATION_ABSTRACT_STATUS.DRAFT
        )
          abstract_status = COMMUNICATION_ABSTRACT_STATUS.ERROR;

        return abstract_status;
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    statusMeta(_state) {
      return (communication: Communication): CommunicationStatusMeta => {
        switch (this.getAbstractStatus(communication)) {
          case COMMUNICATION_ABSTRACT_STATUS.DRAFT:
            return {
              status_color: "grey-lighten-1",
              status_label: "Bozza",
            };
          case COMMUNICATION_ABSTRACT_STATUS.PREPARING:
            return {
              status_color: "info",
              status_label: "Preparazione",
            };
          case COMMUNICATION_ABSTRACT_STATUS.ERROR:
            return {
              status_color: "error",
              status_label: "Errore",
            };
          case COMMUNICATION_ABSTRACT_STATUS.SENT:
            return {
              status_color: "success",
              status_label: "Inviata",
            };
        }
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    meta(_state) {
      return (communication: Communication): CommunicationMeta => {
        const store = this.factory(communication.type);

        return {
          ...this.statusMeta(communication),
          icon: store.icon,
          title: store.title,
        };
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasSubmissionsReady(_state) {
      return (communication: Communication): boolean => {
        switch (communication.status) {
          case COMMUNICATION_STATUS.DRAFT:
          case COMMUNICATION_STATUS.VALIDATION:
          case COMMUNICATION_STATUS.PREPARING:
            return false;
          case COMMUNICATION_STATUS.SENDING:
          case COMMUNICATION_STATUS.SENDING_FAILED:
          case COMMUNICATION_STATUS.SENT:
            return true;
        }
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isProblematic(_state) {
      return (
        communication: Communication,
        { ignoreProblematicFlag = false } = {}
      ): boolean => {
        const _isProblematicFlagSet = ignoreProblematicFlag
          ? true
          : !communication.notProblematic;
        return (
          [
            CONCERN_STATUS.PROBLEMATIC,
            CONCERN_STATUS.READTRACKING_UNREAD,
          ].includes(communication.lowerConcernStatus) && _isProblematicFlagSet
        );
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    get_query_by_filter(_state) {
      return (filter: COMMUNICATION_FILTER, type?: COMMUNICATION_TYPE) => {
        const store = type ? this.factory(type) : this;

        switch (filter) {
          case COMMUNICATION_FILTER.DRAFT:
            return store.queries.draft;
          case COMMUNICATION_FILTER.PROCESSING:
            return store.queries.processing;
          case COMMUNICATION_FILTER.PROBLEMATIC:
            return store.queries.problematic;
          case COMMUNICATION_FILTER.UNREAD:
            return store.queries.unread;
          case COMMUNICATION_FILTER.DELIVERED:
            return store.queries.delivered;
          case COMMUNICATION_FILTER.ALL:
            return store.queries.all;
        }
      };
    },
  },
  actions: {
    injectCommunicationType: <T extends QueryRequestDto>(
      query: T,
      type: COMMUNICATION_TYPE
    ) => ({
      ...query,
      where: {
        ...query.where,
        type,
      },
    }),

    getOne(id: string) {
      return httpService.aGet(`${this.endpoint}/${id}`);
    },

    getOneReport(idCommunication: string) {
      return httpService.aGet(
        `${this.endpoint}/${idCommunication}/concern-report`
      );
    },

    getMany<Query extends Record<string, unknown>>(q: Query) {
      return httpService.aPost<Query, Communication>(
        `${this.endpoint}/search`,
        q
      );
    },

    getManyCount<Query extends Record<string, unknown>>(q: Query) {
      return httpService.aPost<Query, Communication>(
        `${this.endpoint}/search/count`,
        q
      );
    },

    updateOne<Query extends Record<string, unknown>>(id: string, q: Query) {
      return httpService.aPatch<Query, Communication>(
        `${this.endpoint}/${id}`,
        q
      );
    },

    updateProblematicState<Query extends Record<string, unknown>>(
      id: string,
      newProblematicState?: boolean
    ) {
      return httpService.aPatch<Query, Communication>(
        `${this.endpoint}/${id}`,
        {
          notProblematic: !newProblematicState,
        } as unknown as Query
      );
    },

    cloneOne<Query extends Record<string, unknown>>(
      id: string,
      newCommunicationData: {
        type: string;
        object: string;
        oldProblematic?: boolean;
        onlyProblematic?: boolean;
        modeOfSend?: boolean;
        radioModeOfSend?: string;
      }
    ) {
      return httpService.aPost<Query, Communication>(
        `${this.endpoint}/${id}/clone?object=${encodeURIComponent(
          newCommunicationData.object
        )}${
          newCommunicationData.onlyProblematic ? "&problematic_only=true" : ""
        }`,
        {} as Query
      );
    },
  },
});

export default store;
export type CommunicationsStore = ReturnType<typeof store>;
export type SpecificCommunicationTypeStore =
  | CondoCorrespondencesStore
  | CorrespondencesStore
  | DeliveriesStore
  | TrackedDeliveriesStore;
