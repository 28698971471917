import {
  Communication,
  COMMUNICATION_TYPE,
  MODE_OF_SEND,
} from "@/common/entities/Communication";
import { objFromSchema } from "@/common/helpers/objects";
import { httpService } from "@/common/services/http";
import { defineStore } from "pinia";
import * as yup from "yup";

import useCommunicationsStore from "@/resources/communications/stores";
import { QueryRequestDto } from "@/common/types/QueryDIA";

const store = defineStore("condoCorrespondences", {
  state: () => ({
    // API
    endpoint: `${process.env.VUE_APP_AMMINISTRO_API_GATEWAY}/api/v2/communications`,

    // METADATA
    title: "Avvisi condominiali",
    icon: "fa:fas fa-paste",

    // ROUTING
    newRoute: "newCondoCorrespondence",
    viewRoute: "viewCondoCorrespondences",
    openRoute: "viewCondoCorrespondence",
    editRoute: "editCondoCorrespondence",

    // FORMS
    schemas: {
      update: yup.object({
        object: yup.string().min(2).max(80).required().label("oggetto"),
        body: yup.string().min(2).required().label("corpo dell'avviso"),
        notes: yup
          .string()
          .default("")
          .label("note")
          .nullable()
          .transform((value: any) => (value ? value : null)),
      }),

      create: yup.object({
        object: yup.string().min(2).max(80).required().label("oggetto"),
        body: yup.string().min(2).required().label("corpo dell'avviso"),
        notes: yup
          .string()
          .default("")
          .label("note")
          .nullable()
          .transform((value: any) => (value ? value : null)),
        type: yup
          .string()
          .default(COMMUNICATION_TYPE.AVVISO_CONDOMINIALE)
          .label("tipo"),
        modeOfSend: yup
          .string()
          .default(MODE_OF_SEND.EMAIL_CONDOMINIALE)
          .label("metodo di invio"),
      }),
    },

    _communicationsQueries: useCommunicationsStore().queries,
    _injectCommunicationType: <T extends QueryRequestDto>(q: T) =>
      useCommunicationsStore().injectCommunicationType(
        q,
        COMMUNICATION_TYPE.AVVISO_CONDOMINIALE
      ),
  }),

  getters: {
    queries(state) {
      return {
        count: state._injectCommunicationType(
          state._communicationsQueries.count
        ),

        all: state._injectCommunicationType(state._communicationsQueries.all),

        draft: state._injectCommunicationType(
          state._communicationsQueries.draft
        ),

        sent: state._injectCommunicationType(state._communicationsQueries.sent),

        problematic: state._injectCommunicationType(
          state._communicationsQueries.problematic
        ),

        processing: state._injectCommunicationType(
          state._communicationsQueries.processing
        ),

        saved: state._injectCommunicationType(
          state._communicationsQueries.saved
        ),

        unread: state._injectCommunicationType(
          state._communicationsQueries.unread
        ),

        delivered: state._injectCommunicationType(
          state._communicationsQueries.delivered
        ),

        create(data: Record<string, any>) {
          return objFromSchema(state.schemas.create, data);
        },
        update(data: Record<string, any>) {
          return objFromSchema(state.schemas.update, data);
        },
      };
    },
  },

  actions: {
    getOne(id: string) {
      return httpService.aGet<Communication>(`${this.endpoint}/${id}`);
    },

    getOneReport(idCommunication: string) {
      return httpService.aGet(
        `${this.endpoint}/${idCommunication}/concern-report`
      );
    },

    getMany<Query extends Record<string, unknown>>(q: Query) {
      return httpService.aPost<Query, Communication[]>(
        `${this.endpoint}/search`,
        q
      );
    },

    getManyCount<Query extends Record<string, unknown>>(q: Query) {
      return httpService.aPost<Query, Communication>(
        `${this.endpoint}/search/count`,
        q
      );
    },

    createOne<Query extends Record<string, unknown>>(q: Query) {
      return httpService.aPost<Query, string>(`${this.endpoint}`, q);
    },

    updateOne<Query extends Record<string, unknown>>(id: string, q: Query) {
      return httpService.aPatch<Query, Communication>(
        `${this.endpoint}/${id}`,
        q
      );
    },

    sendOne(id: string) {
      return httpService.aPatch(`${this.endpoint}/${id}/send`, undefined);
    },

    deleteOne(id: string) {
      return httpService.aDelete<Communication>(`${this.endpoint}/${id}`);
    },
  },
});

export default store;
export type CondoCorrespondencesStore = ReturnType<typeof store>;
