import { bind } from "@/common/decorators/bind";
import axios, { AxiosRequestConfig } from "axios";
import { HttpInterceptorsService } from "./interceptors";

const AXIOS = axios.create();

export class HttpService {
  constructor(
    protected http = AXIOS,
    protected interceptors = new HttpInterceptorsService(AXIOS)
  ) {}

  @bind
  async aGet<ResDTO>(url: string, config?: AxiosRequestConfig) {
    return this.http.get<ResDTO>(url, config);
  }

  @bind
  async aPost<BodyDTO, ResDTO>(
    url: string,
    body: BodyDTO,
    config?: AxiosRequestConfig
  ) {
    return this.http.post<ResDTO>(url, body, config);
  }

  @bind
  async aPut<BodyDTO, ResDTO>(
    url: string,
    body: BodyDTO,
    config?: AxiosRequestConfig
  ) {
    return this.http.put<ResDTO>(url, body, config);
  }

  @bind
  async aPatch<BodyDTO, ResDTO>(
    url: string,
    body: BodyDTO,
    config?: AxiosRequestConfig
  ) {
    return this.http.patch<ResDTO>(url, body, config);
  }

  @bind
  async aDelete<ResDTO>(url: string, config?: AxiosRequestConfig) {
    return this.http.delete<ResDTO>(url, config);
  }
}

export const httpService = new HttpService();
