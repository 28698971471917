import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteLocationRaw,
  RouteRecordRaw,
} from "vue-router";
import useBuildingsStore from "@/resources/buildings/stores";
import { waitUntilAuthenticationIsSolved } from "@/common/plugins/auth";
import { COMMUNICATION_TYPE } from "@/common/entities/Communication";
import { routerListener } from "@/common/services/routerListener";

async function waitUntilBuildingIsLoaded(buildingId: string) {
  await waitUntilAuthenticationIsSolved();
  const buildingsStore = useBuildingsStore();
  if (buildingId && buildingId !== buildingsStore.building?.id) {
    await buildingsStore.setActiveBuildingFromId(buildingId as string);
  } else {
    // DO NOTHING, BUILDING IS NOT SETTED OR HAS BEEN ALREADY LOADED
  }
}

function getCleanedTo(
  to: RouteLocationNormalized
): RouteLocationRaw | undefined {
  if (to.query.tkn && to.query.profile_uid) {
    const query = Object.assign({}, to.query);
    delete query.tkn;
    delete query.profile_uid;
    return {
      ...to,
      query,
    };
  } else {
    return undefined;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: {
      name: "Dashboard",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    // TODO: creare PageNotFound
    redirect: {
      name: "Dashboard",
    },
  },
  {
    path: "/dashboard/:building?",
    component: () => import("@/app/views/Dashboard.vue"),
    props: true,
    name: "Dashboard",
    meta: {
      title: "Dashboard",
    },
    async beforeEnter(to, from, next) {
      await waitUntilBuildingIsLoaded(to.params.building as string);
      const cleanedTo = getCleanedTo(to);
      cleanedTo ? next(cleanedTo) : next();
    },
  },
  {
    path: "/new/:building?",
    component: () => import("@/app/views/Main.vue"),
    props: true,
    name: "New",
    async beforeEnter(to, from, next) {
      await waitUntilBuildingIsLoaded(to.params.building as string);
      const cleanedTo = getCleanedTo(to);
      cleanedTo ? next(cleanedTo) : next();
    },
    children: [
      {
        name: "newCorrespondence",
        component: () => import("@/resources/correspondences/views/edit.vue"),
        path: "correspondence",
        meta: {
          title: "Nuova comunicazione",
          type: COMMUNICATION_TYPE.COMUNICAZIONE,
        },
      },
      {
        name: "newCondoCorrespondence",
        component: () =>
          import("@/resources/condoCorrespondences/views/edit.vue"),
        path: "condo-correspondence",
        meta: {
          title: "Nuovo avviso condominiale",
          type: COMMUNICATION_TYPE.COMUNICAZIONE,
        },
      },
      {
        name: "newDelivery",
        component: () => import("@/resources/deliveries/views/edit.vue"),
        path: "delivery",
        meta: {
          title: "Nuovo invio posta",
          type: COMMUNICATION_TYPE.POSTA,
        },
      },
      {
        name: "newTrackedDelivery",
        component: () =>
          import("@/resources/tracked-deliveries/views/edit.vue"),
        path: "tracked-delivery",
        meta: {
          title: "Nuovo invio posta tracciata",
          type: COMMUNICATION_TYPE.POSTA_TRACCIATA,
        },
      },
    ],
  },
  {
    path: "/edit/:building?",
    component: () => import("@/app/views/Main.vue"),
    props: true,
    name: "Edit",
    async beforeEnter(to, from, next) {
      await waitUntilBuildingIsLoaded(to.params.building as string);
      const cleanedTo = getCleanedTo(to);
      cleanedTo ? next(cleanedTo) : next();
    },
    children: [
      {
        name: "editCorrespondence",
        component: () => import("@/resources/correspondences/views/edit.vue"),
        path: "correspondence/:id",
        props: true,
        meta: {
          title: "Modifica comunicazione",
          type: COMMUNICATION_TYPE.COMUNICAZIONE,
        },
      },
      {
        name: "editCondoCorrespondence",
        component: () =>
          import("@/resources/condoCorrespondences/views/edit.vue"),
        path: "condo-correspondence/:id",
        props: true,
        meta: {
          title: "Modifica avviso condominiale",
          type: COMMUNICATION_TYPE.AVVISO_CONDOMINIALE,
        },
      },
      {
        name: "editDelivery",
        component: () => import("@/resources/deliveries/views/edit.vue"),
        path: "delivery/:id",
        props: true,
        meta: {
          title: "Modifica posta",
          type: COMMUNICATION_TYPE.POSTA,
        },
      },
      {
        name: "editTrackedDelivery",
        component: () =>
          import("@/resources/tracked-deliveries/views/edit.vue"),
        path: "tracked-delivery/:id",
        props: true,
        meta: {
          title: "Modifica posta tracciata",
          type: COMMUNICATION_TYPE.POSTA_TRACCIATA,
        },
      },
    ],
  },
  {
    path: "/view/:building?",
    component: () => import("@/app/views/Main.vue"),
    props: true,
    name: "View",
    async beforeEnter(to, from, next) {
      await waitUntilBuildingIsLoaded(to.params.building as string);
      const cleanedTo = getCleanedTo(to);
      cleanedTo ? next(cleanedTo) : next();
    },
    children: [
      {
        name: "viewCommunications",
        component: () => import("@/resources/communications/views/views.vue"),
        path: "",
        meta: {
          title: "Tutte le comunicazioni",
          back: { name: "Dashboard" },
        },
      },
      {
        name: "viewCorrespondence",
        component: () => import("@/resources/correspondences/views/view.vue"),
        path: "correspondence/:id",
        props: true,
        meta: {
          title: "Lettura comunicazione",
          back: { name: "viewCorrespondences" },
          type: COMMUNICATION_TYPE.COMUNICAZIONE,
        },
      },
      {
        name: "viewCorrespondences",
        component: () => import("@/resources/communications/views/views.vue"),
        path: "correspondences",
        meta: {
          title: "Comunicazioni",
          back: { name: "Dashboard" },
          type: COMMUNICATION_TYPE.COMUNICAZIONE,
        },
      },
      {
        name: "viewCondoCorrespondences",
        component: () => import("@/resources/communications/views/views.vue"),
        path: "condo-correspondences",
        meta: {
          title: "Avvisi condominiali",
          back: { name: "Dashboard" },
          type: COMMUNICATION_TYPE.AVVISO_CONDOMINIALE,
        },
      },
      {
        name: "viewCondoCorrespondence",
        component: () =>
          import("@/resources/condoCorrespondences/views/view.vue"),
        path: "condo-correspondences/:id",
        props: true,
        meta: {
          title: "Lettura avviso condominiale",
          back: { name: "viewCondoCorrespondences" },
          type: COMMUNICATION_TYPE.AVVISO_CONDOMINIALE,
        },
      },
      {
        name: "viewDelivery",
        component: () => import("@/resources/deliveries/views/view.vue"),
        path: "delivery/:id",
        props: true,
        meta: {
          title: "Lettura posta",
          back: { name: "viewDeliveries" },
          type: COMMUNICATION_TYPE.POSTA,
        },
      },
      {
        name: "viewDeliveries",
        component: () => import("@/resources/communications/views/views.vue"),
        path: "deliveries",
        meta: {
          title: "Posta",
          back: { name: "Dashboard" },
          type: COMMUNICATION_TYPE.POSTA,
        },
      },
      {
        name: "viewTrackedDelivery",
        component: () =>
          import("@/resources/tracked-deliveries/views/view.vue"),
        path: "tracked-delivery/:id",
        props: true,
        meta: {
          title: "Lettura posta tracciata",
          back: { name: "viewTrackedDeliveries" },
          type: COMMUNICATION_TYPE.POSTA_TRACCIATA,
        },
      },
      {
        name: "viewTrackedDeliveries",
        component: () => import("@/resources/communications/views/views.vue"),
        path: "tracked-deliveries",
        meta: {
          title: "Posta Tracciata",
          back: { name: "Dashboard" },
          type: COMMUNICATION_TYPE.POSTA_TRACCIATA,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  routerListener.set();
  next();
});

router.afterEach(() => routerListener.unset());

export default router;
