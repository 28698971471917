import { pipe } from "@/common/helpers/functions";
import { clean, clone } from "@/common/helpers/objects";
import { profileUID, tokens } from "@/common/plugins/auth";
import { AxiosRequestConfig } from "axios";

export class HttpUpdatersService {
  updateHeaders(config: AxiosRequestConfig<any>) {
    config.headers = {
      xAmministroProfile: profileUID.value,
      Authorization: `Bearer ${tokens.value?.accessToken}`,
      "access-control-allow-origin": "*",
      ...config.headers,
    };
    return config;
  }

  updatePayload(config: AxiosRequestConfig<any>) {
    if (config.data instanceof FormData) return config;

    if (Array.isArray(config.data)) {
      config.data = { data: config.data };
      return config;
    }

    if (config.data) {
      const offset =
        "next_offset_key" in config.data
          ? config.data.next_offset_key
          : undefined;

      delete config.data.next_offset_key;

      config.data = {
        data: pipe(clean, clone)(config.data),
        next_offset_key: offset,
      };

      return config;
    } else {
      return config;
    }
  }
}
