import { WithData } from "@/common/types/WithData";

export const identity = <T>(t: T) => t;

export const pipe =
  <Fn extends (p: any) => any>(...fns: Fn[]) =>
  (p: any) =>
    fns.reduce((acc, curr) => curr(acc), p);

export const pipeAsync =
  (...fns: ((p: any) => any)[]) =>
  async (p: unknown) => {
    let res = p;
    for (const fn of fns) {
      res = await fn(res);
    }
    return res;
  };

export function makeChunks<T>(
  arr: Array<T>,
  chunkSize: number
): Array<Array<T>> {
  const chunks = [];

  if (chunkSize < 0) chunkSize = arr.length;

  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }

  return chunks;
}

export const last = <T>(array: T[]) => array.at(array.length - 1);

export const range = (start: number, stop: number, step = 1, precision = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index) =>
    Number((start + index * step).toPrecision(precision))
  );

export const isTextTruncated = (
  text: string,
  width: number,
  fontSizePx = 16
) => {
  const canvas = document.createElement("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");
  ctx!.font = `${fontSizePx}px Roboto`;
  return ctx!.measureText(text).width > width;
};

export const debounced = <T extends (...args: any[]) => any>(
  callback: T,
  delay: number
) => {
  let timeout: number | undefined = undefined;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(...args), delay) as unknown as number;
  };
};

export const groupBy = <T extends Record<string, any>>(
  list: T[],
  property: keyof T
) => {
  const grouped: Record<string, T[]> = {};
  list.forEach((item) => {
    grouped[item[property]] ??= [];
    grouped[item[property]]?.push(item);
  });
  return grouped;
};

export const extractData =
  <T, F extends (...args: any[]) => Promise<WithData<T>>>(fn: F) =>
  (...args: Parameters<typeof fn>) =>
    fn(...args).then(({ data }) => data);

export const getUrlSearchParam = (key: string) =>
  new URLSearchParams(window.location.search).get(key);
