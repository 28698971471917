export type QueryIncludeSequelize = {
  as: string;
  attributes: Array<string>;
  include: Array<QueryIncludeSequelize>;
  where: Record<string, Record<string, unknown>>;
  required: boolean;
};

export type QuerySequelize = {
  attributes: Array<string>;
  include: Array<QueryIncludeSequelize>;
  offset: number;
  limit: number;
  order?: Array<[string, "ASC" | "DESC"]>;
  where: Record<string, Record<string, unknown>>;
};

export const baseQuery = {
  attributes: [],
  include: [],
  offset: 0,
  limit: 36,
  where: {},
} as QuerySequelize;

export const baseIncludeQuery = {
  as: "",
  attributes: [],
  include: [],
  where: {},
  required: false,
} as QueryIncludeSequelize;
