import {
  COMMUNICATION_PROVIDER,
  COMMUNICATION_TYPE,
  MODE_OF_SEND,
} from "@/common/entities/Communication";
import { Nullish } from "@/common/types/Nullish";
import { Attachment } from "@/common/entities/Attachment";
import { Building } from "@/common/entities/Building";

export type DestinationType = "email" | "physical-address" | "pec";

export enum SUBMISSION_READ_STATUS {
  UNREAD = 0,
  READ = 1,
  UNREAD_BECAUSE_OF_ERROR = 2,
}

export enum TRACK_STATUS {
  WITH_ERRORS = -2,
  CANCELLED = -1,
  DELIVERING_TO_PROVIDER = 10,
  DELIVERED_TO_PROVIDER = 11,
  PROCESSING_AT_PROVIDER = 12,
  ERRORS_AT_PROVIDER = 13,
  SENT_TO_RECIPIENT = 20,
  DELIVERED_TO_RECIPIENT = 21,
  READ = 30,
}

export enum CONCERN_STATUS {
  PROBLEMATIC = 1,
  READTRACKING_UNREAD = 2,
  PROCESSING = 3,
  SAVED = 4,
}

export type RecipientInfo = {
  destination: Nullish<string>;
  destinationType: DestinationType;
  denomination: string;
};

export type TrackInfo = {
  provider: COMMUNICATION_PROVIDER;
  providerModeOfSend: MODE_OF_SEND;
  updateMode: "push" | "pull";
  status: TRACK_STATUS;
};

export type Submission = {
  attachments: Array<Attachment>;
  body: string;
  concernStatus: CONCERN_STATUS;
  delegatedRecipientInfo: RecipientInfo;
  id: string;
  object: string;
  printedBody: Nullish<string>;
  resent: boolean;
  readStatus: SUBMISSION_READ_STATUS;
  sendingDate: string;
  trackInfo: TrackInfo;
  type: COMMUNICATION_TYPE;
  _building: Pick<Building, "name" | "id">;
  _contactDelegatedRecipient: string;
  _contactRecipient: string;
  _entitlement: string;
  _userDelegatedRecipient: string;
  _userRecipient: string;
};

export enum SUBMISSION_FILTER {
  ALL = "Mostra tutti",
  MISSING_DESTINATION_ERRORS = "Senza recapito",
  OTHER_ERRORS = "In errore",
  UNREAD = "Non lette",
  DELIVERED = "Consegnate",
}

export enum SUBMISSION_ISSUE_CODE {
  UNKNOWN = "UNKNOWN",
  MISSING_EMAIL_ADDRESS = "MISSING_EMAIL_ADDRESS",
  MISSING_PHYSICAL_ADDRESS = "MISSING_PHYSICAL_ADDRESS",
  MISSING_DIRECT_ENTITLEMENT = "MISSING_DIRECT_ENTITLEMENT",
  RECIPIENT_CANT_LOGIN = "RECIPIENT_CANT_LOGIN",
  PROVIDER_ERROR_INVALID_CREDENTIALS = "PROVIDER_ERROR_INVALID_CREDENTIALS",
  PROVIDER_ERROR_CANT_SEND = "PROVIDER_ERROR_CANT_SEND",
  PROVIDER_ERROR_CANT_CHECK_TRACK_STATUS = "PROVIDER_ERROR_CANT_CHECK_TRACK_STATUS",
}
