
import { defineComponent, inject } from "vue";
import LoadingLayout from "@/common/layouts/Loading.vue";
import BaseLayout from "@/common/layouts/Base.vue";
import LoadingSpinner from "@/common/components/LoadingSpinner.vue";
import { loadingSpinner } from "@/common/services/loadingSpinner";
import DialogServiceComponent from "@/common/components/DialogServiceComponent.vue";

export default defineComponent({
  name: "App",

  components: {
    LoadingSpinner,
    LoadingLayout,
    BaseLayout,
    DialogServiceComponent,
  },

  setup() {
    return {
      profile: inject("profile"),
      loadingSpinner,
    };
  },

  computed: {
    loading(): boolean {
      return this.profile === undefined;
    },
  },
});
