import { Directive } from "vue";

export const focus = {
  mounted(el: HTMLElement) {
    setTimeout(() => {
      let input = null;

      if (el instanceof HTMLInputElement) {
        input = el;
      } else {
        input = el.querySelector("input");
      }
      input?.focus();
      input?.click();
    }, 100);
  },
} as Directive;
