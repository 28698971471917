import { SORT_DIRECTION } from "../composables/sort";

export enum TrackStatus {
  CANCELLED = 100,
  DELIVERING_TO_PROVIDER = 1,
  DELIVERED_TO_PROVIDER = 2,
  SENT_TO_RECIPIENT = 3,
  DELIVERED_TO_RECIPIENT = 4,
  READ = 5,
}

export type QueryRequestDto = {
  full_text?: string;
  where?: Record<string, unknown>;
  limit?: number;
  offset?: number;
  offset_key?: string;
  order?: [string, SORT_DIRECTION.ASC | SORT_DIRECTION.DESC][];
};

export const baseQuery = {
  full_text: undefined,
  where: undefined,
  limit: undefined,
  offset: undefined,
  offset_key: undefined,
} as QueryRequestDto;
