import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_app_bar = _resolveComponent("base-app-bar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_app_bar, { onRefresh: _ctx.updateKey }, null, 8, ["onRefresh"]),
    _createVNode(_component_v_container, {
      class: "bg-grey-lighten-6 pa-0",
      fluid: "",
      style: {"height":"calc(100vh - 96px)"}
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", { key: _ctx.key })
      ]),
      _: 3
    })
  ], 64))
}