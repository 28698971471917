// https://github.com/andreypopp/autobind-decorator/blob/master/src/index.js

export function bind<T extends Record<string, any>>(
  target: T,
  key: string,
  descriptor: PropertyDescriptor
): PropertyDescriptor {
  let fn = descriptor.value;

  if (typeof fn !== "function") {
    throw new TypeError(
      `@bind decorator can only be applied to methods not: ${typeof fn}`
    );
  }

  return {
    configurable: true,
    get() {
      // eslint-disable-next-line no-prototype-builtins
      if (this === target.prototype || typeof fn !== "function") {
        return fn;
      } else {
        // Memoize
        const memo = fn.bind(this);

        // Override the pd with the one which uses the memoized version
        Object.defineProperty(this, key, {
          configurable: true,
          get() {
            return memo;
          },
          set(value) {
            fn = value;
            delete this[key];
          },
        });

        return memo;
      }
    },

    set(value: any) {
      fn = value;
    },
  };
}
