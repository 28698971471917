import { bind } from "@/common/decorators/bind";
import { pipe } from "@/common/helpers/functions";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { HttpParserService } from "./parsers";
import { HttpUpdatersService } from "./updaters";

export class HttpInterceptorsService {
  constructor(
    readonly http: AxiosInstance,
    readonly httpUpdaters = new HttpUpdatersService(),
    readonly httpParsers = new HttpParserService()
  ) {
    this.onInit();
  }

  onInit() {
    this.http.interceptors.request.use(
      this.onRequestSuccessInterceptor,
      this.onRequestErrorInterceptor
    );

    this.http.interceptors.response.use(
      this.onResponseSuccessInterceptor,
      this.onResponseErrorInterceptor
    );
  }

  @bind
  onRequestErrorInterceptor(err: unknown) {
    console.error(err);
    return err;
  }

  @bind
  onRequestSuccessInterceptor(config: AxiosRequestConfig<any>) {
    return pipe(
      this.httpUpdaters.updateHeaders,
      this.httpUpdaters.updatePayload
    )(config);
  }

  @bind
  onResponseSuccessInterceptor(res: AxiosResponse<any, any>) {
    return pipe(this.httpParsers.parseResponse)(res);
  }
  @bind
  onResponseErrorInterceptor(err: unknown) {
    return pipe(this.httpParsers.parseError)(err);
  }
}
