import { initializeApp } from "firebase/app";
import { Profile } from "@amministro-io-packages/auth-interfaces";
import { computed, Plugin, ref, watch } from "vue";
import {
  signInWithCustomToken,
  getAuth,
  User,
  onIdTokenChanged,
  signOut,
  getIdToken,
  browserSessionPersistence,
} from "firebase/auth";
import { Maybe } from "@/common/types/Maybe";
import { Nullish } from "@/common/types/Nullish";
import { pipe } from "../helpers/functions";

type MaybeNullish<T> = Maybe<Nullish<T>>;

const app = initializeApp({
  appId: process.env.VUE_APP_FB_APPID,
  projectId: process.env.VUE_APP_FB_PROJECTID,
  apiKey: process.env.VUE_APP_FB_APIKEY,
  authDomain: process.env.VUE_APP_FB_AUTHDOMAIN,
});

const auth = getAuth(app);
auth.setPersistence(browserSessionPersistence);

auth.tenantId = process.env.VUE_APP_TENANT_ID;

localStorage.removeItem("force-logout");

window.addEventListener("storage", (evt) => {
  if (evt.key === "force-logout" && evt.newValue) {
    localStorage.removeItem("force-logout");
    window.open(process.env.VUE_APP_SUPERLOGIN_URL, "_self");
  }
});

function extractFromStorage(key: string) {
  return localStorage.getItem(key);
}

function extractFromParams(key: string) {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
}

async function getProfiles(
  accessToken: string
): Promise<{ status: "ok" | "ko"; payload: Profile[] }> {
  try {
    const res = await fetch(
      `${process.env.VUE_APP_AMMINISTRO_API_GATEWAY}/api/v2/users/auth/profiles`,
      {
        headers: {
          "access-control-allow-origin": "*",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!res.ok) {
      const err = await res.json();
      throw err;
    }

    const payload = (await res.json()).data;

    return {
      status: "ok",
      payload,
    };
  } catch (e) {
    return {
      status: "ko",
      // @ts-expect-error ts(2339)
      payload: e + "",
    };
  }
}

async function getCustomToken(
  accessToken: string
): Promise<{ status: "ok" | "ko"; payload: string }> {
  try {
    const res = await fetch(
      `${process.env.VUE_APP_AMMINISTRO_API_GATEWAY}/api/v2/users/auth/custom-tokens`,
      {
        headers: {
          "access-control-allow-origin": "*",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!res.ok) {
      const err = await res.json();
      throw err;
    }

    const token = (await res.json()).data;

    const payload = token;

    return {
      status: "ok",
      payload,
    };
  } catch (e) {
    return {
      status: "ko",
      payload: e + "",
    };
  }
}

export const user =
  ref<MaybeNullish<User & { profiles: Profile[] }>>(undefined);
export const profileUID = ref<MaybeNullish<string>>(undefined);
export const tokens = ref<
  MaybeNullish<{
    customToken: string;
    accessToken: string;
  }>
>(undefined);

export function cleanURL() {
  const oldParams = new URLSearchParams(window.location.search);

  const newKeys = [...oldParams.keys()].filter(
    (key) => !["tkn", "profile_uid"].includes(key)
  );

  const newParams = new URLSearchParams(
    newKeys.map((key) => [key, oldParams.get(key)!])
  );

  const newURL = [
    window.location.origin,
    window.location.pathname,
    newParams.toString() !== "" ? `?${newParams.toString()}` : "",
  ].join("");

  window.history.replaceState({}, "", newURL);
}

export function waitUntilAuthenticationIsSolved() {
  return new Promise((res) => {
    if (user.value) res(user.value);
    else {
      watch(user, (v) => {
        cleanURL();
        res(v);
      });
    }
  });
}

function addOnlineListener() {
  window.addEventListener("online", () => {
    if (auth.currentUser) {
      updateIdToken();
    } else {
      logout(false);
    }
  });
}

function redirect(
  fragments: string[],
  params: Record<string, string | undefined>,
  target: "_self" | "_blank"
) {
  const cleanedParams = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value !== undefined)
  ) as Record<string, string>;
  const queryString = new URLSearchParams(cleanedParams).toString();
  const baseUrl = process.env.VUE_APP_SUPERLOGIN_URL;

  window.open(
    [baseUrl, ...fragments].join("/") + (queryString ? "?" + queryString : ""),
    target
  );
}

async function updateIdToken() {
  if (auth.currentUser) {
    getIdToken(auth.currentUser);
  }
}

function addHealthCheck() {
  setInterval(() => {
    if (auth.currentUser) {
      updateIdToken();
    } else {
      logout(false);
    }
  }, 60 * 1000);
}

function addOnVisibleListener() {
  document.addEventListener("visibilitychange", () => {
    if (!document.hidden) {
      if (auth.currentUser) {
        updateIdToken();
      } else {
        logout(false);
      }
    }
  });
}

function addBeforeUnloadListener() {
  window.addEventListener("beforeunload", () => {
    localStorage.setItem("profile_uid", profileUID.value as string);
    if (!profileUID.value) localStorage.removeItem("profile_uid");
  });
}

function startSession() {
  const customToken = extractFromParams("tkn");
  return customToken
    ? signInWithCustomToken(auth, customToken)
    : new Promise((r) => r(undefined));
}

function addOnIdTokenChangedListener() {
  onIdTokenChanged(auth, async (fbUser) => {
    if (fbUser) {
      const accessToken = await fbUser.getIdToken();
      const { status: tokenStatus, payload: customToken } =
        await getCustomToken(accessToken);
      if (tokenStatus !== "ok") return logout(true);
      tokens.value = { accessToken, customToken };
      const { status, payload: data } = await getProfiles(
        tokens.value?.accessToken
      );
      if (status !== "ok") return logout(true);
      user.value = { ...fbUser, profiles: data as unknown as Profile[] };
      profileUID.value =
        profileUID.value ||
        extractFromParams("profile_uid") ||
        extractFromStorage("profile_uid");
    } else {
      redirect(
        [],
        {
          to: window.location.href,
          profile_uid: localStorage.getItem("profile_uid") ?? undefined,
        },
        "_self"
      );
    }
  });
}

export async function logout(inplace: boolean) {
  if (inplace) {
    try {
      await signOut(auth);
    } catch (e) {
      console.error(e);
    }
    redirect([], { to: process.env.VUE_APP_PRODUCT_URL as string }, "_self");
  } else {
    redirect(["logout"], {}, "_self");
  }
}

export default {
  install(app) {
    startSession().then(
      pipe(
        addOnlineListener,
        addBeforeUnloadListener,
        addOnVisibleListener,
        addOnIdTokenChangedListener,
        addHealthCheck
      )
    );

    app.provide(
      "user",
      computed(() => user.value)
    );
    app.provide(
      "tokens",
      computed(() => tokens.value)
    );

    /**
     * profile undefined -> info utente in caricamento
     * profile null -> profilo inesistente
     */
    app.provide(
      "profile",
      computed(() => {
        if (!user.value?.profiles) return undefined;
        return (
          user.value?.profiles.find(
            (pr) =>
              pr.xAmministroProfile.split(":")[2] ===
              profileUID.value?.split(":")[2]
          ) || null
        );
      })
    );

    app.config.globalProperties.$logout = logout;
  },
} as Plugin;
