
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      dialog: false,
      title: "",
      description: "",
      successButtonText: "",
      errorButtonText: "",
      showErrorButton: true,
    };
  },

  mounted() {
    window.addEventListener("show-dialog", this.showDialog);
    window.addEventListener("close-dialog", this.closeDialog);
  },

  unmounted() {
    window.removeEventListener("show-dialog", this.showDialog);
    window.removeEventListener("close-dialog", this.closeDialog);
  },

  methods: {
    showDialog(e: Event) {
      const {
        title,
        description,
        successButtonText,
        errorButtonText,
        showErrorButton,
      } = (e as CustomEvent).detail;

      this.title = title;
      this.description = description;
      this.successButtonText = successButtonText;
      this.errorButtonText = errorButtonText;
      this.showErrorButton = showErrorButton;

      this.$nextTick(() => {
        this.dialog = true;
      });
    },

    closeDialog() {
      this.dialog = false;
    },

    successButtonClick() {
      window.dispatchEvent(
        new CustomEvent("success-button-click", {
          detail: { successButtonClicked: true },
        })
      );
      this.dialog = false;
    },

    errorButtonClick() {
      window.dispatchEvent(
        new CustomEvent("error-button-click", {
          detail: { errorButtonClicked: true },
        })
      );
      this.dialog = false;
    },
  },
});
