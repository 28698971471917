import { ref } from "vue";

class RouterListener {
  private _isChangingRoute = ref(false);

  public set() {
    this._isChangingRoute.value = true;
  }

  public unset() {
    this._isChangingRoute.value = false;
  }

  public get isChangingRoute() {
    return this._isChangingRoute.value;
  }
}

const routerListener = new RouterListener();

export { routerListener };
