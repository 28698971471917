import "@/common/styles/toast.css";
import { makeBinded } from "@/common/helpers/objects";
import { AxiosError } from "axios";

class ToastService {
  private _snack!: HTMLDivElement;
  private _timer: number | undefined = undefined;

  constructor() {
    this._init();
  }

  private _init() {
    window.addEventListener("popstate", this._hide.bind(this));
    this._snack = document.createElement("div");
    this._snack.id = "alert-snack";
    this._snack.setAttribute("class", "alert-snack");
    this._hide();
    document.body.appendChild(this._snack);
  }

  private _hide() {
    this._snack.classList.add("hidden");
    this._snack.innerHTML = "";
  }

  private _show() {
    this._snack.classList.remove("hidden");
  }

  showError(error: unknown) {
    const parseError = (e: unknown): string => {
      if (error instanceof AxiosError) {
        return error.response!.data.message;
      } else if (typeof e === "string") {
        return e;
      } else if (typeof e === "object" && e !== null) {
        if ("data" in e) {
          if (typeof e.data === "string") {
            return e.data;
          } else if (typeof e.data === "object" && e.data === null) {
            return "Si è verificato un errore imprevisto";
          } else if (typeof e.data === "object" && "message" in e.data!) {
            return e.data.message as string;
          } else {
            return "Si è verificato un errore imprevisto";
          }
        } else if ("message" in e) {
          return e.message as string;
        } else {
          return "Si è verificato un errore imprevisto";
        }
      } else {
        return "Si è verificato un errore imprevisto";
      }
    };

    console.error(error);
    this._snack.innerHTML = `<p></p><button>chiudi</button>`;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._snack.querySelector("button")!.onclick = this._hide.bind(this);
    clearTimeout(this._timer);
    this._snack.setAttribute("type", "error");
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._snack.querySelector("p")!.innerHTML = parseError(error);
    this._show();
    this._timer = setTimeout(this._hide.bind(this), 6000);
  }

  showSuccess(successText: string) {
    this._snack.innerHTML = `<p></p>`;
    clearTimeout(this._timer);
    this._snack.setAttribute("type", "success");
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._snack.querySelector("p")!.innerHTML = successText;
    this._show();
    this._timer = setTimeout(this._hide.bind(this), 4000);
  }
}

export const toastService = makeBinded(ToastService);
