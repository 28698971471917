import { Maybe } from "../types/Maybe";

export type PrintOptions = {
  brochure: Maybe<boolean>;
  printType: Maybe<PrintTypeOptions>[];
  pageSize: Maybe<PageSizeOptions>[];
  color: Maybe<ColorOptions>[];
  // printTypeDefault: PrintTypeOptions;
  // pageSizeDefault: PageSizeOptions;
  // colorDefault: ColorOptions;
};

export enum PrintTypeOptions {
  BACK_ONLY = "back-only",
  FRONT_BACK = "front-back",
}

export enum PageSizeOptions {
  A4 = "A4",
  A4_A3 = "A4-A3",
}

export enum ColorOptions {
  NORMAL = "normal",
  BLACK_AND_WHITE = "black-and-white",
}
