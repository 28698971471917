import { logout } from "@/common/plugins/auth";
import { STATUS_CODES } from "@/common/types/StatusCodes";
import { AxiosResponse, AxiosError } from "axios";

export class HttpParserService {
  parseResponse(res: AxiosResponse<any, any>) {
    // @ts-expect-error ts(2722)
    if (res.headers.getContentType().indexOf("application/json") >= 0) {
      if (res.data) {
        if (
          typeof res.data === "object" &&
          res.data !== null &&
          "data" in res.data
        )
          res = res.data;
      }
    }
    return res;
  }

  parseError(err: unknown) {
    if (err instanceof AxiosError) {
      const { status } = err.response || {};

      // La sessione dell'utente non è più valida
      if (status === STATUS_CODES.INVALID_PROFILE) {
        return logout(true);
      }

      if (status === STATUS_CODES.CONTENT_TOO_LARGE && err.response) {
        err.response.data.message =
          "La dimensione di uno o più file è troppo grande";
        throw err;
      }
    }

    throw err;
  }
}
