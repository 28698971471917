/* eslint-disable no-extra-boolean-cast */
import {
  COMMUNICATION_TYPE,
  CommunicationExtended,
  MODE_OF_SEND,
} from "@/common/entities/Communication";
import {
  ColorOptions,
  PageSizeOptions,
  PrintTypeOptions,
} from "@/common/entities/PrintOptions";
import { objFromSchema } from "@/common/helpers/objects";
import { httpService } from "@/common/services/http";
import { defineStore } from "pinia";
import * as yup from "yup";

import useBuildingsStore from "@/resources/buildings/stores";
import useCommunicationsStore from "@/resources/communications/stores";
import { QueryRequestDto } from "@/common/types/QueryDIA";

const store = defineStore("trackedDeliveries", {
  state: () => ({
    // API
    endpoint: `${process.env.VUE_APP_AMMINISTRO_API_GATEWAY}/api/v2/communications`,

    // METADATA
    title: "Posta tracciata",
    icon: "fa:fas fa-envelope-circle-check",

    // ROUTING
    newRoute: "newTrackedDelivery",
    viewRoute: "viewTrackedDeliveries",
    openRoute: "viewTrackedDelivery",
    editRoute: "editTrackedDelivery",

    // FORMS
    get schemas() {
      return {
        update: yup.object({
          object: yup.string().min(2).max(80).required().label("oggetto"),
          body: yup.string().min(2).required().label("corpo dell'avviso"),
          notes: yup
            .string()
            .min(2)
            .default("")
            .label("note")
            .nullable()
            .transform((value: any) => (!!value ? value : null)),
          type: yup
            .string()
            .default(COMMUNICATION_TYPE.POSTA_TRACCIATA)
            .label("tipo"),
          modeOfSend: yup.string().label("metodo di invio"),
          printOptions: yup
            .object({
              brochure: yup.boolean(),
              printType: yup
                .mixed()
                .oneOf([
                  PrintTypeOptions.BACK_ONLY,
                  PrintTypeOptions.FRONT_BACK,
                ]),
              pageSize: yup
                .mixed()
                .oneOf([PageSizeOptions.A4, PageSizeOptions.A4_A3]),
              color: yup
                .mixed()
                .oneOf([ColorOptions.BLACK_AND_WHITE, ColorOptions.NORMAL]),
            })
            .nullable(),
          ignoreContactsPreferences: yup.boolean(),
        }),

        create: yup.object({
          buildingsTargetId: yup
            .array(yup.string())
            .optional()
            .default(
              useBuildingsStore().activeBuildingId
                ? [useBuildingsStore().activeBuildingId]
                : undefined
            ),
          object: yup.string().min(2).max(80).required().label("oggetto"),
          body: yup.string().min(2).required().label("corpo dell'avviso"),
          notes: yup
            .string()
            .min(2)
            .nullable()
            .default("")
            .label("note")
            .transform((value: any) => (!!value ? value : null)),
          type: yup
            .string()
            .default(COMMUNICATION_TYPE.POSTA_TRACCIATA)
            .label("tipo"),
          modeOfSend: yup.string().label("metodo di invio"),
          printOptions: yup
            .object({
              brochure: yup.boolean(),
              printType: yup
                .mixed()
                .oneOf([
                  PrintTypeOptions.BACK_ONLY,
                  PrintTypeOptions.FRONT_BACK,
                ]),
              pageSize: yup
                .mixed()
                .oneOf([PageSizeOptions.A4, PageSizeOptions.A4_A3]),
              color: yup
                .mixed()
                .oneOf([ColorOptions.BLACK_AND_WHITE, ColorOptions.NORMAL]),
            })
            .nullable(),
          ignoreContactsPreferences: yup.boolean(),
        }),
      };
    },

    _communicationsQueries: useCommunicationsStore().queries,
    _injectCommunicationType: <T extends QueryRequestDto>(q: T) =>
      useCommunicationsStore().injectCommunicationType(
        q,
        COMMUNICATION_TYPE.POSTA_TRACCIATA
      ),
  }),
  getters: {
    queries(state) {
      return {
        count: state._injectCommunicationType(
          state._communicationsQueries.count
        ),

        all: state._injectCommunicationType(state._communicationsQueries.all),

        draft: state._injectCommunicationType(
          state._communicationsQueries.draft
        ),

        sent: state._injectCommunicationType(state._communicationsQueries.sent),

        problematic: state._injectCommunicationType(
          state._communicationsQueries.problematic
        ),

        processing: state._injectCommunicationType(
          state._communicationsQueries.processing
        ),

        saved: state._injectCommunicationType(
          state._communicationsQueries.saved
        ),

        unread: state._injectCommunicationType(
          state._communicationsQueries.unread
        ),

        delivered: state._injectCommunicationType(
          state._communicationsQueries.delivered
        ),

        create(data: Record<string, any>) {
          return objFromSchema(state.schemas.create, data);
        },

        update(data: Record<string, any>) {
          return objFromSchema(state.schemas.update, data);
        },
      };
    },
  },
  actions: {
    getOne(id: string) {
      return httpService.aGet<CommunicationExtended>(`${this.endpoint}/${id}`);
    },

    getOneReport(idCommunication: string) {
      return httpService.aGet(
        `${this.endpoint}/${idCommunication}/concern-report`
      );
    },

    getMany<Query extends Record<string, unknown>>(q: Query) {
      return httpService.aPost<Query, CommunicationExtended[]>(
        `${this.endpoint}/search`,
        q
      );
    },

    async getManyReport<Query extends Record<string, unknown>>(q: Query) {
      const reports: { [index: string]: unknown } = {};

      // @ts-expect-error TODO
      const { data: communications, next_offset_key } = await this.getMany(q);
      for (const item of communications) {
        reports[item.id] = await this.getOneReport(item.id);
      }

      return {
        data: reports,
        next_offset_key: next_offset_key,
      };
    },

    getManyCount<Query extends Record<string, unknown>>(q: Query) {
      return httpService.aPost<Query, number>(
        `${this.endpoint}/search/count`,
        q
      );
    },

    createOne<Query extends Record<string, unknown>>(q: Query) {
      return httpService.aPost<Query, string>(`${this.endpoint}`, q);
    },

    updateOne<Query extends Record<string, unknown>>(id: string, q: Query) {
      return httpService.aPatch<Query, CommunicationExtended>(
        `${this.endpoint}/${id}`,
        q
      );
    },

    sendOne(id: string) {
      return httpService.aPatch(`${this.endpoint}/${id}/send`, undefined);
    },

    deleteOne(id: string) {
      return httpService.aDelete<CommunicationExtended>(
        `${this.endpoint}/${id}`
      );
    },
  },
});

export default store;
export type TrackedDeliveriesStore = ReturnType<typeof store>;
