import { Attachment } from "@/common/entities/Attachment";
import { Building } from "@/common/entities/Building";

export enum COMMUNICATION_TYPE {
  COMUNICAZIONE = "corrispondenza",
  AVVISO_CONDOMINIALE = "avviso-condominiale",
  POSTA = "posta",
  POSTA_TRACCIATA = "posta-tracciata",
}

export enum COMMUNICATION_PROVIDER {
  MINNOVI = "minnovi",
  PTDOC = "ptdoc",
  //DOCUMENTI_POSTALI = "documenti-postali",
}

export enum COMMUNICATION_STATUS {
  DRAFT = "DRAFT",
  VALIDATION = "VALIDATION",
  PREPARING = "PREPARING",
  SENDING = "SENDING",
  SENDING_FAILED = "SENDING_FAILED",
  SENT = "SENT",
}

export enum COMMUNICATION_ABSTRACT_STATUS {
  DRAFT = "DRAFT",
  /** Richiede un'azione da parte dell'amministratore per procedere con l'invio della comunicazione */
  ERROR = "ERROR",
  /** Stato generico che indica che la comunicazione e' in fase di preparazione o invio presso i provider */
  PREPARING = "PREPARING",
  SENT = "SENT",
}

export enum COMMUNICATION_ERROR_CODE {
  /** Unknown error occured */
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  /** Generic validation error occurred, used when no specific code are available */
  VALIDATION_ERROR = "VALIDATION/ERROR",
  /** Generic error while preparing communication */
  PREPARING_ERROR = "PREPARING/ERROR",
  /** Generic error while sending communication */
  SENDING_ERROR = "SENDING/ERROR",
  /** The provider is unavailable */
  PROVIDER_UNAVAILABLE = "PROVIDER/UNAVAILABLE",
  /** Communication has no mode of send */
  VALIDATION_NO_MODE_OF_SEND = "VALIDATION/NO_MODE_OF_SEND",
  /** Communication type and mode of send are incompatible */
  VALIDATION_COMMUNICATION_TYPE_INCOMPATIBLE_WITH_MODE_OF_SEND = "VALIDATION/COMMUNICATION_TYPE_INCOMPATIBLE_WITH_MODE_OF_SEND",
  /** Communication has no recipients */
  VALIDATION_NO_RECIPIENTS = "VALIDATION/NO_RECIPIENTS",
  /** A building has invalid identifier */
  VALIDATION_BUILDING_INVALID = "VALIDATION/BUILDING_INVALID",
  /** A building is disabled */
  VALIDATION_BUILDING_DISABLED = "VALIDATION/BUILDING_DISABLED",
  /** The mode of send is not enabled */
  VALIDATION_MODE_OF_SEND_NOT_ENABLED = "VALIDATION/MODE_OF_SEND_NOT_ENABLED",
  /** Common pdf can't be generated */
  PREPARING_CANT_GENERATE_COMMON_PDF = "PREPARING/CANT_GENERATE_COMMON_PDF",
  /** The saved credentials of the sender are missing or invalid */
  PROVIDER_INVALID_CREDENTIALS = "PROVIDER/INVALID_CREDENTIALS",
}

export enum COMMUNICATION_TRACKING_STATUS {
  CANCELLED = 100,
  DELIVERING_TO_PROVIDER = 1,
  DELIVERED_TO_PROVIDER = 2,
  SENT_TO_RECIPIENT = 3,
  DELIVERED_TO_RECIPIENT = 4,
  READ = 5,
}

export enum MODE_OF_SEND {
  EMAIL = "email",
  EMAIL_CONDOMINIALE = "email-condominiale",
  CORRISPONDENZA_AL = "corrispondenza-al",
  CORRISPONDENZA = "corrispondenza",
  POSTA_ORDINARIA = "posta-ordinaria",
  POSTA_PRIORITARIA = "posta-prioritaria",
  RACCOMANDATA = "raccomandata",
  RACCOMANDATA_1 = "raccomandata-1",
  RACCOMANDATA_AR = "raccomandata-ar",
  RACCOMANDATA_AR_1 = "raccomandata-ar-1",
  RACCOMANDATA_SPID = "raccomandata-spid",
  PEC = "pec",
}

export enum COMMUNICATION_FILTER {
  DRAFT = "In bozza",
  PROCESSING = "In elaborazione",
  PROBLEMATIC = "Problematiche",
  UNREAD = "Non lette",
  DELIVERED = "Consegnate",
  ALL = "Mostra tutte",
}

export type Communication = {
  body: string;
  buildingsTarget: Building[];
  code: string;
  commonAttachments: Array<Attachment>;
  lowerConcernStatus: number;
  createdAt: string;
  id: string;
  notes: string;
  object: string;
  notProblematic: boolean;
  provider: COMMUNICATION_PROVIDER;
  modeOfSend: MODE_OF_SEND;
  sendingDate: null;
  status: COMMUNICATION_STATUS;
  type: COMMUNICATION_TYPE;
  _organizationSender: string;
  lastIssues: { error?: string; code: COMMUNICATION_ERROR_CODE }[];
};

export interface CommunicationExtended extends Communication {
  openCloneDialog: boolean;
}
