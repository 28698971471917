import { httpService } from "@/common/services/http";
import { Building } from "@/common/entities/Building";
import { Maybe } from "@/common/types/Maybe";
import { Nullish } from "@/common/types/Nullish";
import { baseQuery, QuerySequelize } from "@/common/types/QuerySequelize";
import { defineStore } from "pinia";

export type BuildingStore = {
  endpoint: string;
  building: Nullish<Maybe<Building>>;
  buildings: Nullish<Maybe<Building[]>>;
};

export default defineStore("buildings", {
  state: (): BuildingStore => ({
    endpoint: `${process.env.VUE_APP_AMMINISTRO_API_GATEWAY}/api/v2/buildings`,
    building: undefined,
    buildings: undefined,
  }),
  getters: {
    activeBuildingId(state) {
      return state.building?.id;
    },

    isBuildingActive(state) {
      return state.building?.status !== 0;
    },

    isBuildingPrivate(state) {
      return state.building?.isPrivate;
    },

    activeBuilding(state) {
      return state.building;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    queries(_state) {
      return {
        active: {
          ...baseQuery,
          where: { status: { $eq: 1 } },
          order: [["name", "ASC"]],
        },

        activeNotPrivate: {
          ...baseQuery,
          where: { status: { $eq: 1 }, isPrivate: false },
          order: [["name", "ASC"]],
        },

        disabled: {
          ...baseQuery,
          where: { status: { $eq: 0 } },
          order: [["name", "ASC"]],
        },

        all: {
          ...baseQuery,
          order: [["name", "ASC"]],
        },
      };
    },
  },
  actions: {
    async setActiveBuildingFromId(id: string) {
      try {
        const { data } = await this.getOne(id);
        this.setActiveBuilding(data as unknown as Building);
      } catch (e) {
        console.error(e);
        this.setActiveBuilding(null as unknown as Building);
      }
    },

    setActiveBuilding(building: Nullish<Building>) {
      this.building = building;
    },

    setMany(buildings: Building[]) {
      this.buildings = [...(this.buildings || []), ...buildings];
    },

    getOne(id: string) {
      return httpService.aGet(`${this.endpoint}/${id}`);
    },

    getMany(q: QuerySequelize) {
      return httpService.aPost<QuerySequelize, Building>(
        `${this.endpoint}/search`,
        q
      );
    },
  },
});
