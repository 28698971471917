// Styles
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { mdi } from "vuetify/iconsets/mdi";
import { fa } from "vuetify/iconsets/fa";
// @ts-expect-error ts(2339)
import colors from "vuetify/lib/util/colors";
// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
      fa,
    },
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: colors.deepOrange.base,
          secondary: colors.deepOrange.lighten4,
          warning: "#ffc107",
          error: colors.red.base,
        },
      },

      dark: {
        colors: {
          primary: colors.deepOrange.base,
          secondary: colors.deepOrange.lighten4,
          warning: "#ffc107",
          error: colors.red.base,
        },
      },
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
