import { App, createApp, defineAsyncComponent } from "vue";

// STYLES
import "@/app/main.css";
import { loadFonts } from "@/common/plugins/webfontloader";

import AppVue from "@/app/views/App.vue";

// PLUGINS
import router from "@/app/router";
import auth from "@/common/plugins/auth";
import env from "@/common/plugins/env";
import pinia from "@/common/plugins/pinia";
import vuetify from "@/common/plugins/vuetify";

// VALIDATIONS
import "@/common/i18n/validations";

// HELPERS
import { pipe } from "@/common/helpers/functions";

// SERVICES
import { toastService } from "@/common/services/toast";

// DIRECTIVES
import { focus } from "@/common/directives/focus";
import { versionListener } from "@/common/services/versionListener";
import { dialogService } from "@/common/services/dialogService";

function installComponents(app: App) {
  app.component(
    "base-drag-drop",
    defineAsyncComponent(() => import("@/common/components/DragAndDrop.vue"))
  );

  app.component(
    "base-clone-communication",
    defineAsyncComponent(
      () =>
        import("@/common/components/cloneCommunication/CloneCommunication.vue")
    )
  );

  app.component(
    "base-filter",
    defineAsyncComponent(() => import("@/common/components/Filter.vue"))
  );

  app.component(
    "base-overflow-observer",
    defineAsyncComponent(
      () => import("@/common/components/OverflowObserver.vue")
    )
  );

  app.component(
    "base-overflow-observer-group",
    defineAsyncComponent(
      () => import("@/common/components/OverflowObserverGroup.vue")
    )
  );

  app.component(
    "base-dialog",
    defineAsyncComponent(() => import("@/common/components/Dialog.vue"))
  );

  app.component(
    "base-select",
    defineAsyncComponent(() => import("@/common/components/Select.vue"))
  );

  app.component(
    "base-data-provider",
    defineAsyncComponent(() => import("@/common/components/DataProvider.vue"))
  );

  app.component(
    "base-portal",
    defineAsyncComponent(() => import("@/common/components/Portal.vue"))
  );

  app.component(
    "base-refresh-provider",
    defineAsyncComponent(
      () => import("@/common/components/RefreshProvider.vue")
    )
  );

  app.component(
    "base-launcher",
    defineAsyncComponent(() => import("@/common/components/Launcher.vue"))
  );

  app.component(
    "base-debounce-provider",
    defineAsyncComponent(
      () => import("@/common/components/DebounceProvider.vue")
    )
  );

  app.component(
    "base-search-input",
    defineAsyncComponent(() => import("@/common/components/Search.vue"))
  );

  app.component(
    "base-preview",
    defineAsyncComponent(() => import("@/common/components/Preview.vue"))
  );

  app.component(
    "base-no-results",
    defineAsyncComponent(() => import("@/common/components/NoResults.vue"))
  );

  app.component(
    "base-md-editor",
    defineAsyncComponent(() => import("@/common/components/MdEditor.vue"))
  );

  app.component(
    "base-md-viewer",
    defineAsyncComponent(() => import("@/common/components/MdViewer.vue"))
  );

  app.component(
    "base-checkbox",
    defineAsyncComponent(() => import("@/common/components/Checkbox.vue"))
  );

  app.component(
    "base-all-done",
    defineAsyncComponent(() => import("@/common/components/AllDone.vue"))
  );

  app.component(
    "base-info",
    defineAsyncComponent(() => import("@/common/components/Info.vue"))
  );

  app.component(
    "base-chip",
    defineAsyncComponent(() => import("@/common/components/Chip.vue"))
  );

  app.component(
    "base-label-chip",
    defineAsyncComponent(() => import("@/common/components/LabelChip.vue"))
  );

  return app;
}

function installDirectives(app: App) {
  app.directive("focus", focus);
  return app;
}

function installPlugins(app: App) {
  app.use(pinia);
  app.use(router);
  app.use(auth);
  app.use(env);
  app.use(vuetify);
  return app;
}

function mount(app: App) {
  app.config.unwrapInjectedRef = true;
  app.config.errorHandler = toastService.showError;
  app.mount("#app");
  return app;
}

pipe<(p: App<Element>) => App<Element>>(
  loadFonts,
  installPlugins,
  installComponents,
  installDirectives,
  mount
)(createApp(AppVue));

versionListener.init(() => {
  dialogService
    .showDialog(
      {
        title: "Attenzione",
        description: `È disponibile una nuova versione di ${process.env.VUE_APP_PRODUCT_DISPLAY_NAME}. Per continuare, ricarica la pagina.`,
        successButtonText: "Ricarica",
        errorButtonText: "",
      },
      false
    )
    .then(() => location.reload());
});
