import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_layout = _resolveComponent("loading-layout")!
  const _component_dialog_service_component = _resolveComponent("dialog-service-component")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_base_layout = _resolveComponent("base-layout")!
  const _component_v_theme_provider = _resolveComponent("v-theme-provider")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_loading_layout, { key: 0 }))
            : (_openBlock(), _createBlock(_component_v_theme_provider, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_layout, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_dialog_service_component),
                      _createVNode(_component_LoadingSpinner, {
                        "model-value": _ctx.loadingSpinner.loading
                      }, null, 8, ["model-value"]),
                      _createVNode(_component_router_view)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}