import { ref } from "vue";

class LoadingSpinner {
  private _loading = ref(false);
  public set() {
    this._loading.value = true;
  }
  public unset() {
    this._loading.value = false;
  }
  public get loading() {
    return this._loading.value;
  }
}

const loadingSpinner = new LoadingSpinner();

export { loadingSpinner };
