import {
  Communication,
  COMMUNICATION_TYPE,
  MODE_OF_SEND,
} from "@/common/entities/Communication";
import { objFromSchema } from "@/common/helpers/objects";
import { httpService } from "@/common/services/http";
import { defineStore } from "pinia";
import * as yup from "yup";

import useBuildingsStore from "@/resources/buildings/stores";

import useCommunicationsStore from "@/resources/communications/stores";
import { QueryRequestDto } from "@/common/types/QueryDIA";

const store = defineStore("correspondences", {
  state: () => ({
    // API
    endpoint: `${process.env.VUE_APP_AMMINISTRO_API_GATEWAY}/api/v2/communications`,

    // METADATA
    title: "Comunicazioni",
    icon: "fa:fas fa-inbox",

    // ROUTING
    newRoute: "newCorrespondence",
    viewRoute: "viewCorrespondences",
    openRoute: "viewCorrespondence",
    editRoute: "editCorrespondence",

    // FORMS
    get schemas() {
      return {
        update: yup.object({
          object: yup.string().min(2).max(80).required().label("oggetto"),
          body: yup.string().min(2).required().label("corpo dell'avviso"),
          notes: yup
            .string()
            .default("")
            .label("note")
            .nullable()
            .transform((value: any) => (value ? value : null)),
        }),

        create: yup.object({
          buildingsTargetId: yup
            .array(yup.string())
            .optional()
            .default(
              useBuildingsStore().activeBuildingId
                ? [useBuildingsStore().activeBuildingId]
                : undefined
            ),
          object: yup.string().min(2).max(80).required().label("oggetto"),
          body: yup.string().min(2).required().label("corpo dell'avviso"),
          notes: yup
            .string()
            .default("")
            .label("note")
            .nullable()
            .transform((value: any) => (value ? value : null)),
          type: yup
            .string()
            .default(COMMUNICATION_TYPE.COMUNICAZIONE)
            .label("tipo"),
          modeOfSend: yup
            .string()
            .default(MODE_OF_SEND.EMAIL)
            .label("metodo di invio"),
        }),
      };
    },

    _communicationsQueries: useCommunicationsStore().queries,
    _injectCommunicationType: <T extends QueryRequestDto>(q: T) =>
      useCommunicationsStore().injectCommunicationType(
        q,
        COMMUNICATION_TYPE.COMUNICAZIONE
      ),
  }),

  getters: {
    queries(state) {
      return {
        count: state._injectCommunicationType(
          state._communicationsQueries.count
        ),

        all: state._injectCommunicationType(state._communicationsQueries.all),

        draft: state._injectCommunicationType(
          state._communicationsQueries.draft
        ),

        sent: state._injectCommunicationType(state._communicationsQueries.sent),

        problematic: state._injectCommunicationType(
          state._communicationsQueries.problematic
        ),

        processing: state._injectCommunicationType(
          state._communicationsQueries.processing
        ),

        saved: state._injectCommunicationType(
          state._communicationsQueries.saved
        ),

        unread: state._injectCommunicationType(
          state._communicationsQueries.unread
        ),

        delivered: state._injectCommunicationType(
          state._communicationsQueries.delivered
        ),

        create(data: Record<string, any>) {
          return objFromSchema(state.schemas.create, data);
        },
        update(data: Record<string, any>) {
          return objFromSchema(state.schemas.update, data);
        },
      };
    },
  },

  actions: {
    getOne(id: string) {
      return httpService.aGet<Communication>(`${this.endpoint}/${id}`);
    },

    getOneReport(idCommunication: string) {
      return httpService.aGet(
        `${this.endpoint}/${idCommunication}/concern-report`
      );
    },

    getMany<Query extends Record<string, unknown>>(q: Query) {
      return httpService.aPost<Query, Communication[]>(
        `${this.endpoint}/search`,
        q
      );
    },

    getManyCount<Query extends Record<string, unknown>>(q: Query) {
      return httpService.aPost<Query, Communication>(
        `${this.endpoint}/search/count`,
        q
      );
    },

    createOne<Query extends Record<string, unknown>>(q: Query) {
      return httpService.aPost<Query, string>(`${this.endpoint}`, q);
    },

    updateOne<Query extends Record<string, unknown>>(id: string, q: Query) {
      return httpService.aPatch<Query, Communication>(
        `${this.endpoint}/${id}`,
        q
      );
    },

    sendOne(id: string) {
      return httpService.aPatch(`${this.endpoint}/${id}/send`, undefined);
    },

    deleteOne(id: string) {
      return httpService.aDelete<Communication>(`${this.endpoint}/${id}`);
    },
  },
});

export default store;
export type CorrespondencesStore = ReturnType<typeof store>;
