
import { defineAsyncComponent, defineComponent, ref } from "vue";
import useCommunicationStore from "@/resources/communications/stores";
import useBuildingStore from "@/resources/buildings/stores";

export default defineComponent({
  name: "base-app-bar",

  setup() {
    const communicationStore = useCommunicationStore();
    const buildingStore = useBuildingStore();

    return {
      communicationStore,
      buildingStore,
      console,
    };
  },

  components: {
    BaseApplications: defineAsyncComponent(
      () => import("@/resources/user/components/Applications.vue")
    ),
    BaseProfiles: defineAsyncComponent(
      () => import("@/resources/user/components/Profiles.vue")
    ),
  },
  emits: ["refresh"],
  computed: {
    isCreatingPage() {
      return (
        this.$route.fullPath === "/new/correspondence" ||
        this.$route.fullPath === "/new/delivery" ||
        this.$route.fullPath === "/new/tracked-delivery"
      );
    },
  },
  methods: {
    onBack() {
      const { back } = this.$route.meta;
      if (back) {
        this.$router.push(back as { name: string });
      } else {
        this.$router.back();
      }
    },
  },
});
