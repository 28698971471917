
import { defineComponent } from "vue";
import BaseAppBar from "@/common/components/Appbar.vue";
import useRefresh from "../composables/refresh";

export default defineComponent({
  name: "base-layout",
  components: {
    BaseAppBar,
  },
  setup() {
    const { key, updateKey } = useRefresh();
    return {
      key,
      updateKey,
    };
  },
});
