import { Maybe } from "@/common/types/Maybe";
import { MaybeArray } from "../types/MaybeArray";

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const deepmerge = require("deepmerge");

export const isEmptyObject = <T extends Record<string, any>>(obj: T) =>
  Object.keys(obj).length === 0 ||
  Object.keys(obj).every((key) => obj[key] === undefined);

export const clean = <T extends Maybe<Record<string, any>>>(obj: T) => {
  if (
    Array.isArray(obj) ||
    typeof obj !== "object" ||
    obj === null ||
    isEmptyObject(obj)
  ) {
    return obj;
  } else {
    return cleanHelper(obj);
  }
};

const cleanHelper = <T extends Maybe<Record<string, any>>>(obj: T) => {
  if (typeof obj !== "object") return obj;
  if (obj === null) return obj;
  if (Array.isArray(obj)) return obj;
  if (isEmptyObject(obj)) return undefined;

  for (const key in obj) {
    obj[key] = cleanHelper(obj[key]);
  }

  return obj;
};

export function clone<T extends Record<string, any>>(obj: T): T | undefined {
  if ("structuredClone" in window) {
    return window.structuredClone(obj) as T;
  } else {
    return JSON.parse(JSON.stringify(obj)) as T;
  }
}

const BinderHandler: ProxyHandler<Record<string | symbol, any>> = {
  get(target, p) {
    if (typeof target[p] === "function") {
      target[p] = (target[p] as (...args: any[]) => any).bind(target);
    }
    // @ts-expect-error ts(2556)
    // eslint-disable-next-line prefer-rest-params
    return Reflect.get(...arguments);
  },
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function makeBinded<T extends object>(Target: { new (): T }): T {
  const binded = new Proxy(new Target(), BinderHandler) as T;
  return binded;
}

export function objFromSchema<T extends Record<string, any>>(
  schema: T,
  obj: any
) {
  const target = {} as Record<string, any>;
  Object.keys(schema.fields).forEach((key) => {
    target[key] = obj[key];
  });
  return target;
}

export const take = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keys: K[]
) => {
  return Object.fromEntries(keys.map((key) => [key, obj[key]])) as Pick<T, K>;
};

export const transformEntries = <T extends Record<string, any>>(
  obj: T,
  transformer: ([key, value]: [string, T[keyof T]]) => [string, any]
) => Object.fromEntries(Object.entries(obj).map(transformer));

export const filterEntries = <T extends Record<string, any>>(
  obj: T,
  filter: ([key, value]: [string, T[keyof T]]) => boolean
) => Object.fromEntries(Object.entries(obj).filter(filter));

export const exclude = <
  T extends Record<string | symbol | number, any>,
  K extends keyof T
>(
  obj: T,
  excluded: MaybeArray<K>
): Omit<T, K> =>
  take(
    obj,
    Object.keys(obj).filter((k) =>
      Array.isArray(excluded)
        ? !excluded.some((_excluded) => _excluded === k)
        : k !== excluded
    ) as Exclude<keyof T, K>[]
  );
