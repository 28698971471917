/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

import { App } from "vue";

export function loadFonts(app: App<Element>) {
  import(/* webpackChunkName: "webfontloader" */ "webfontloader").then(
    (webFontLoader) => {
      webFontLoader.load({
        google: {
          families: ["Roboto:100,300,400,500,700,900&display=swap"],
        },
      });
    }
  );

  return app;
}
