import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.$env('VUE_APP_AMMINISTROIO_LOGO_MINI'),
      class: "animate__animated animate__pulse animate__infinite amministro-loading"
    }, null, 8, _hoisted_2)
  ]))
}