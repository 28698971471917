import { ref } from "vue";
import { unique } from "@/common/helpers/random";

export default function useRefresh() {
  const key = ref<string>(unique());
  const updateKey = () => {
    key.value = unique();
  };

  return {
    key,
    updateKey,
  };
}
