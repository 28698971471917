import { makeBinded } from "@/common/helpers/objects";

class DialogService {
  public showDialog(
    dialogInfo: {
      title: string;
      description: string;
      successButtonText: string;
      errorButtonText: string;
    },
    showErrorButton = true
  ) {
    return new Promise((res, rej) => {
      window.dispatchEvent(
        new CustomEvent("show-dialog", {
          detail: { ...dialogInfo, showErrorButton },
        })
      );

      window.addEventListener("success-button-click", res);
      window.addEventListener("error-button-click", rej);
    });
  }
  public closeDialog() {
    window.dispatchEvent(new CustomEvent("close-dialog"));
  }
}

export const dialogService = makeBinded(DialogService);
